import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';

import  PrintPdf  from './PrintPdf';
class PrintForm extends Component {
    componentDidMount() {
     if(localStorage.getItem('sID')=='null'){
        window.location = '/';
     }
    }
    render() {
        return (
            <>
            <div><a className="btn btn-danger" style={{   float: "right", marginRight: "806px", marginLeft: "50px"}} href="/">Go To Home</a></div>
            
            <div style={{marginTop:"130px"}}>
                <ReactToPrint  
                trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return <a className="btn btn-danger" style={{    marginLeft: "50px"}} href="#">Print</a>   ;
                }}
                content={() => this.componentRef}
                />
                <PrintPdf ref={el => (this.componentRef = el)} />
            </div>
            </>
        );
    }
}

export default PrintForm;