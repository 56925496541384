import React,   { useEffect,  useState ,setIsLoaded , Component  } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import dateFormat from 'dateformat';
class PrintPdf extends React.PureComponent {
    constructor(props) {

        super(props);

        this.state = {
            items: [],
            isLoaded: false
        }

    }
     
    componentDidMount() {
        fetch('https://dirasa.in/api/getFormInfo.php?sID='+ localStorage.getItem('sID') )
            .then(res => res.json())
            .then(json => {
                this.setState({
                    items: json,
                    isLoaded: true, 
                })
            }).catch((err) => {
                console.log(err);
            });

    }

    render() {
        const { isLoaded, items } = this.state;
        return (
            <div style={{    padding: "50px",width: "12in"}}>
                <Container style={{borderWidth: "7px", borderStyle: "double", borderColor: "#c31a1a"}}>
                    <Row>
                        <Col>
                           <div className="text-center" style={{marginTop:"50px"}}>
                                <img src="img/header.jpeg" style={{    width:"400px"}}></img>
                               {/* <img src="img/logo1.png" style={{    width:"170px"}}></img> */}
                            </div>
                            <div className="text-center" style={{ color: "red", fontFamily: "'Clibri'", fontSize: "14px",    marginTop: ""}}> Head Office: 420 More (NH-34), Malda, 732101, W.B</div>
                            <div className="text-center" style={{ color: "red", fontFamily: "'Clibri'", fontSize: "14px",    marginTop: ""}}> Contact: 03512295952 / 08697131519</div>
                            <div className="text-center" style={{ color: "red", fontFamily: "'Clibri'", fontSize: "14px",    marginTop: ""}}> Email: support@dirasa.in Website: www.dirasa.in</div>
                            {/* <div className="text-center" style={{ color: "red", fontFamily: "'Open Sans'", fontSize: "15px",    marginTop: "-14px"}}> WE ARE PROVIDING YOU A BETTER FUTURE </div> */}
                            <div className="text-center" style={{     backgroundColor: "red", padding: "10px", borderRadius: "5px",   marginTop: "26px"}}><span style={{backgroundColor: "red",padding: "10px",borderRadius: "10px",color: "#fff",fontWeight: "700",fontSize: "20px"}}>APPLICATION FORM</span></div>
                            <div className="text-center" style={{marginTop: "155px",color: "red",fontWeight: "700",fontSize: "20px"}}>&nbsp;</div>
                            <div className="passportPhoto" style={{borderWidth: "3px", borderStyle: "double", borderColor: "#c31a1a"}}>Photo</div>
                            <div style={{    padding: "41px"}}></div>
                                    {items.map(item => (
                            <div>
                            <Table style={{marginLeft: "35px",fontSize: "18px",fontWeight:" 600",    width: "900px"}} >
                                <tbody>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>FORM NO:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {localStorage.getItem('sID')}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>NAME:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                        {item.name}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>DATE OF BIRTH:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {dateFormat(item.dob, "dddd, mmmm dS, yyyy")}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>GUARDIAN'S NAME:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.gname}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>ADDRESS:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.address}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>COURSE:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.course}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>SESSION:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.session}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>NAME OF SCHOOL:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.school_name}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"240px",color:"red", paddingTop: "17px"}}>MONTH OF ADMISSION:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            &nbsp;
                                        </div>
                                    </td>
                                    </tr>
                                    <tr >
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>STUDENT CONTACT NO:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.contact}
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{border:"none",width:"250px",color:"red", paddingTop: "17px"}}>GUARDIAN CONTACT NO.:</td>
                                    <td style={{border:"none"}}>
                                        <div style={{borderBottom: "1px red dashed",width:"100%",    paddingLeft: "20px",fontFamily: "sans-serif"}}>
                                            {item.gcontact}
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </Table>
                                    
                            <div className="ihereby">
                                <span style={{marginLeft:"65px"}}>I,
                                    <label style={{borderBottom: "1px red dashed",width:"70%"}}>
                                        <span style={{    marginLeft: "47px", color: "#000"}}>{item.name}</span>
                                    </label> 
                                    heere by declare that all the information provided is correct.</span>
                            </div>
                            </div>
                            ))}
                            <div style={{marginTop: "140px", marginBottom: "80px",paddingLeft: "30px", paddingRight: "30px"}}>
                            <Table>
                            <tbody>
                                <tr>
                                    <td style={{border:"none",borderBottom: "1px red dashed"}}>
                                    </td>
                                    <td style={{border:"none"}}></td>
                                    <td style={{border:"none",borderBottom: "1px red dashed"}}></td>
                                </tr>
                                <tr>
                                    <td className="sig" style={{border:"none"}}>SIGNATURE OF STUDENT</td>
                                    <td style={{border:"none",width: "500px"}}></td>
                                    <td className="sig" style={{border:"none"}}>SIGNATURE OF GUARDIAN</td>
                                </tr>
                            </tbody>
                            </Table>
                            </div>
                            {items.map(item => (
                            <div>
                                <span style={{color:" red", fontSize: "10px",    fontWeight: "700"}}>
                                    DATE & TIME OF FORM SUBMITION: </span> {dateFormat(item.entryDate, 'dd-MM-yyyy HH:mm:ss')}
                            </div>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default PrintPdf;