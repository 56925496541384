import React, { Component } from 'react'
import { faCheckCircle, faQuoteLeft, faUserMd, faUserHardHat, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class RegistrationSuccess extends Component {
    constructor(props) {

        super(props);

        this.state = {
            name: "",
            email: "",
            roll_no:"",
        }

    }
    componentDidMount() {
        
        this.setState({
            name:localStorage.getItem('name'),
            email:localStorage.getItem('email'),
            roll_no:localStorage.getItem('roll_no'),
        });
        

    }
  render() {
    return (
      <div className="text-center" style={{marginTop:"200px",marginBottom:"100px",paddingLeft:"50px", alignItems:"center",fontFamily: "none"}} >
         <FontAwesomeIcon icon={faCheckCircle} className="okbig" />
         <h3>Congratulations {this.state.name}</h3>
         <h3 style={{color:"green"}}>Registration completed Successfully</h3>
         <h3>Please noted your roll no. is : {this.state.roll_no}</h3>
         <h5>Please check your registered email (check spam also) <text style={{color:"red"}}>{this.state.email}</text> for Admit card</h5>
         {/* <iframe src="/pdf/d_230417072135.pdf" title="title"> */}
              {/* Presss me: <a href="/pdf/d_230417072135.pdf">Download PDF</a> */}
          {/* </iframe> */}
          <br/>
         <a class="btn btn-primary" href="/" role="button">Go to Home</a>
      </div>
    )
  }
}

