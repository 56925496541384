import React from 'react';
import {Card, Button, Container, Row, Col, CardDeck, CardGroup, Image, Form, InputGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faQuoteLeft, faUserMd, faUserHardHat, faUser, faPhoneAlt, faCalendarAlt, faEnvelope, faBookOpen } from '@fortawesome/free-solid-svg-icons'

const Courses = props => {
    return (
        <>
         <div md={12} xs={12} className="backImg" style={{backgroundImage:"url('img/course.jpg')",width: "100%", height:"300px",backgroundSize: "cover", backgroundRepeat: "no-repeat",    alignItems: "center",marginTop:"70px"}}>
                    <div style={{backgroundColor: "#141415ba", height:"300px"}}>
                    <center><h1 style={{ paddingTop:"150px", color:"#fff",fontWeight: "700"}}>COURSES</h1></center>
                    </div>
                </div>
        <Container>
            <Row>
                <Col md={10} >
                <div style={{marginTop:"100px", }} >
                    <div>
                        <h2 style={{ paddingTop:"40px",paddingLeft:"30px", color:"#d20e0e",fontWeight: "700"}}>NEET-UG </h2>
                    </div>
                    <div style={{ paddingLeft:"40px", color:"#736f6f",fontWeight: "400"}}>
                        Dirasa offers the following programme for students whose target is to crack NEET-UG .
                    </div>
                </div>
                <div>
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                            <small style={{fontSize: "20px",marginLeft: "5px",color:"#d20e0e"}}>2-yr Classroom Programme- </small>
                            <small style={{fontSize: "16px",marginLeft: "5px"}}>For students moving to class XI </small>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                            <small style={{fontSize: "20px",marginLeft: "5px",color:"#d20e0e"}}>1-yr Classroom Programme- </small>
                            <small style={{fontSize: "16px",marginLeft: "5px"}}>For students moving to class XII </small>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                            <small style={{fontSize: "20px",marginLeft: "5px",color:"#d20e0e"}}>1-yr Repeater Programme- </small>
                            <small style={{fontSize: "16px",marginLeft: "5px"}}>For class XII passed out students</small>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                            <small style={{fontSize: "20px",marginLeft: "5px",color:"#d20e0e"}}>1-yr & 2-yr Test Programme- </small>
                            <ol style={{marginLeft: "30%"}}>
                                <li><small style={{fontSize: "15px"}}>Weekly Chapterwise Test</small></li>
                                <li><small style={{fontSize: "15px"}}>Monthly Mock Test</small></li>
                                <li><small style={{fontSize: "15px"}}>Last Full Length Major Test</small></li>
                            </ol>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                            <small style={{fontSize: "20px",marginLeft: "5px",color:"#d20e0e"}}>Capsule Programme- </small>
                            <small style={{fontSize: "16px",marginLeft: "5px"}}>For class XII appearing or pass out students</small>
                        </li>
                    </ul>
                </div>
                <div style={{padding:"10px",marginTop:"10px",marginLeft:"-40px"}}>
                    <small style={{fontSize: "16px"}}>Our 2-yr classroom programme for NEET  simultaneously prepares the students for class XII Board (All Stream),  KVPY and OLYMPIAD.</small>
                    <small style={{fontSize: "16px"}}>We provide Board Material and Unit & Mock Test for All Boards for Physics, Chemistry & Biology.</small>
                </div>
                </Col>
            </Row>
        </Container>
                <div style={{backgroundColor:"#d6c655", marginTop:"5%",paddingTop:"30px",paddingBottom:"50px"}}>
                <Container>
                <Row>
                <Col md={10} >
                    <div>
                        <div style={{textAlign:'center'}}>
                        <text className="CourseListText">Classroom Programmes</text>
                        </div>
                        <div style={{marginTop:"20px",marginBottom:"40px",marginLeft:"10px"}}>
                        <text className="structureButton">Structure</text>
                        </div>
                    </div>
              
                    <div>
                        <div>
                        <ul>
                            <li>
                                <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                                <small style={{fontSize: "16px",marginLeft: "5px"}}>The 2 yr. Classroom programmes has two academic session</small>
                                <div style={{marginLeft:"17px",fontSize: "16px"}}>
                                    <div>The first session for students in class XI.</div>
                                    <div>The second session for students moving to class XII.</div>
                                </div>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                                <small style={{fontSize: "16px",marginLeft: "5px"}}>The 1 yr. Classroom programmes has one academic session for the students of class XII.</small>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faBook} className="CourseListIcon"   />
                                <small style={{fontSize: "16px",marginLeft: "5px"}}>The Repeater Batch Classroom programme is for class XII pass out students and has one academic session.</small>
                            </li>
                        </ul>
                    </div>
                    </div>
                    </Col>
                    </Row>
                </Container>
                </div>
               
               </>
    );
};



export default Courses;