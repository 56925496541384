import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faQuoteLeft,faMapMarkerAlt, faUserMd, faUserHardHat, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const ContactUs = () => {
    return (
            <>
            <div style={{marginTop:"-27px",backgroundColor: "#ededed"}} className="contactTopMargin">
                <div md={12} xs={12} className="text-center" style={{    marginTop: "7%"}}>
                    <h2  style={{ padding:"60px",fontWeight: "700", fontSize:"280%",lineHeight: "83px"}}>Contact Us</h2>
                </div>
                <Container>
                    <Row>
                        <Col className="text-center" md={4}>
                            <div className="text-center">
                                <img className="contactIcon" src="./img/byphone.svg" />
                                <div className="contactText">BY PHONE</div>
                                <p class="desc">
                                    <small style={{color: "#000",fontSize: "14px",fontWeight: "600",fontFamily: "nunito sans,sans-serif"}}>(Monday to Friday, 9am to 6pm PST)</small>
                                    <br />
                                    <span className="contactText2">India:<br />
                                    03512-295952 <br />8697131519
                                    </span>
                                    
                                </p>
                            </div>

                                
                                
                        </Col>
                        <Col className="text-center" md={4}>
                            <div className="text-center">
                                <img className="contactIcon" src="./img/newcase.svg" />
                                <div className="contactText">SEND EMAIL</div>
                                <p class="desc">
                                    <small style={{color: "#000",fontSize: "14px",fontWeight: "600",fontFamily: "nunito sans,sans-serif"}}>(Monday to Friday, 9am to 6pm PST)</small>
                                    <br />
                                    <span className="contactText2">Email:<br />
                                    contact@dirasa.com
                                    </span>
                                    
                                </p>
                            </div>

                                
                                
                        </Col>
                        <Col className="text-center" md={4}>
                            <div className="text-center">
                                <img className="contactIcon" src="./img/livechat.svg" />
                                <div className="contactText">CHAT</div>
                                <p class="desc">
                                    <small style={{color: "#000",fontSize: "14px",fontWeight: "600",fontFamily: "nunito sans,sans-serif"}}>(Monday to Friday, 9am to 6pm PST)</small>
                                    <br />
                                    <span className="contactText2">WhatsApp:<br />
                                    8697131519
                                    </span>
                                    
                                </p>
                            </div>

                                
                                
                        </Col>

                    </Row>
                </Container>
                <div style={{backgroundColor:"#fff", paddingBottom:"7%",paddingTop:"3%"}}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div >
                                    <h2 className="text-center" style={{  fontSize: "300%", lineHeight: "3em",  fontWeight: "700"}}>Our Address</h2>
                                        <Row>
                                            <Col md={3}></Col>
                                            <Col md={3}>
                                            <div className="text-center">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} size="9x" color="#ff8236" />
                                            </div>
                                            </Col>
                                        
                                            <Col md={3}>
                                            <div className="text-center">
                                            <p style={{fontSize: "26px", fontWeight: "700", color: "#606060"}}>www.dirasa.in </p>
                                            <p style={{fontSize: "20px", fontWeight: "600", color: "#606060",textDecoration: "underline"}}>Head Office </p>
                                            <div className="text-center" >
                                                <p style={{fontSize: "25px", fontWeight: "600", color: "#606060"}} className="text-center">  Gabgachi Jadupur,Malda<br />
                                                West Bengal, 732101<br />
                                                India</p>
                                            </div>
                                            </div>
                                            </Col>
                                        </Row>
                                </div>
                                
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            </>
    )
}
export default ContactUs