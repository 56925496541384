import React, { Component } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Card, Button, Container, Row, Col, CardDeck, CardGroup, Image, Form, InputGroup, ListGroup} from 'react-bootstrap';
import Slider from "react-slick";
export default class OurAchiver extends Component {
    render() {
        var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          };
        return (
            <div>
                 <div>
                    <Slider {...settings}>
                    <div className="ourAchiverDiv">
                        <Image src="img/our_achiver/22.jpg" thumbnail className="ourAchiverImg" />
                    </div>
                    <div className="ourAchiverDiv">
                        <Image src="img/our_achiver/55.jpg" thumbnail className="ourAchiverImg"/>
                    </div>
                    <div className="ourAchiverDiv">
                        <Image src="img/our_achiver/44.jpg" thumbnail className="ourAchiverImg"/>
                    </div>
                    <div className="ourAchiverDiv">
                        <Image src="img/our_achiver/11.jpg" thumbnail className="ourAchiverImg"/>
                    </div>
                    <div className="ourAchiverDiv">
                        <Image src="img/our_achiver/33.jpg" thumbnail className="ourAchiverImg"/>
                    </div>
                    
                    </Slider>
                </div>
            </div>
        )
    }
}
