import React from 'react'
import './Footer.css';
import {Card, Button, Container, Row, Col, CardDeck, CardGroup, Image, Form, InputGroup, ListGroup} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faQuoteLeft, faUserHardHat, faUser, faPhoneAlt, faCalendarAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
    return (
           <>
               <div className="containerFluid mt-0" style={{width:"100% !important"}}>
                    <div className="card mx-auto cardFooter">
                        <div className="row mb-1 " >
                            <div className="col-md-4 col-sm-11 col-xs-11">
                                <div className="footer-text pull-left">
                                    <div className="d-flex">
                                        {/* <h1 className="font-weight-bold mr-2 px-3" style={{color:"#16151a" , backgroundColor:"red"}}> D </h1> */}
                                        <img
                                            src="/img/logo1.png"
                                            width="50"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="React Bootstrap logo"
                                        />
                                        <h1 style={{color: "red"}}>dirasa</h1>
                                    </div>
                                    <p className="card-text">Choose your Dream and Make It A Reality .</p>
                                    {/* <div className="social mt-2 mb-3"> <i className="fa fa-facebook-official fa-lg"></i> <i className="fa fa-instagram fa-lg"></i> <i className="fa fa-twitter fa-lg"></i> <i className="fa fa-linkedin-square fa-lg"></i> <i className="fa fa-facebook"></i> </div> */}
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-1 col-xs-1 mb-2"></div>
                            <div className="col-md-2 col-sm-4 col-xs-4">
                                <h5 className="heading">Quick Links</h5>
                                <ul>
                                    <li><a href="https://dirasa.collegedoors.com" id="footera">Online Test Series</a></li>
                                    <li><a href="https://dirasa.collegedoors.com/pages/index.php" id="footera">Students Login</a></li>
                                    
                                </ul>
                            </div>
                            <div className="col-md-2 col-sm-4 col-xs-4">
                                <h5 className="heading">Menu Links</h5>
                                <ul className="card-text">
                                    <li> <a href="/" id="footera">Home</a></li>
                                    <li> <a href="Courses" id="footera">Courses</a></li>
                                    <li> <a href="/img/DirasaProspectus.pdf" id="footera">Prospectus</a></li>
                                    <li> <a href="Registration" id="footera">Admission</a></li>
                                    <li> <a href="AboutUs" id="footera">About Us</a></li>
                                    <li> <a href="ContactUs"  id="footera">Contact Us</a></li>
                                    
                                </ul>
                            </div>
                            <div className="col-md-2 col-sm-4 col-xs-4">
                                <h5 className="heading">Company</h5>
                                <ul className="card-text">
                                    <li ><a id="footera" href="AboutUs">About Us</a></li>
                                    <li ><a id="footera" href="ContactUs">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        
                        <div>
                        <Row>
                        <Col xs={12} md={6}>
                            <div className="text-center" style={{width:"30%"}}>
                            Follow Us
                            <Row style={{height:"100px",padding:"10px"}} className="text-center">
                                
                                <Col xs={4} md={4}>
                                    <a href="https://www.facebook.com/dirasaneet/">
                                    <FontAwesomeIcon icon={faFacebookSquare} className="followUsIcon"/>
                                    </a>
                                </Col>
                                <Col xs={4} md={4}>
                                    <a href="https://twitter.com/Dirasa11?s=08">
                                    <FontAwesomeIcon icon={faTwitterSquare}   className="followUsIcon"/>
                                    </a>
                                </Col>
                                <Col xs={4} md={4}>
                                    <a href="https://www.instagram.com/invites/contact/?i=9fzqpigjvzvw&utm_content=ha6r572">
                                    <FontAwesomeIcon icon={faInstagramSquare}  className="followUsIcon" />
                                    </a>
                                </Col>
                            </Row>
                            </div>
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <div className="text-center" style={{width:"30%"}}>
                            Contact Us
                            <Row style={{height:"300px",padding:"10px"}} className="text-center">
                            <Col xs={4} md={4}>
                                <a href="https://www.facebook.com/dirasaneet/">
                                <FontAwesomeIcon icon={faFacebookSquare} className="followUsIcon"/>
                                </a>
                            </Col>
                            <Col xs={4} md={4}>
                                <a href="https://twitter.com/Dirasa11?s=08">
                                <FontAwesomeIcon icon={faTwitterSquare}   className="followUsIcon"/>
                                </a>
                            </Col>
                            <Col xs={4} md={4}>
                                <a href="https://www.instagram.com/invites/contact/?i=9fzqpigjvzvw&utm_content=ha6r572">
                                <FontAwesomeIcon icon={faInstagramSquare}  className="followUsIcon" />
                                </a>
                            </Col>
                        </Row>
                        </div>
                    </Col> */}
                    </Row>
                        </div>
                        <div className="divider mb-4"> </div>
                        <div className="row" style={{fontSize:"10px"}}>
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="pull-left">
                                    <p><i className="fa fa-copyright"></i> 2021 Dirasa</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6">
                                <div className="pull-right mr-4 d-flex policy">
                                    <div>Terms of Use</div>
                                    <div>Privacy Policy</div>
                                    <div>Cookie Policy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </>
    )
}
export default Footer