import React from 'react';
import {Card, Button, Container, Row, Col, CardDeck, CardGroup, Image, Form, InputGroup} from 'react-bootstrap';

const AboutUs = () => {
    return (
            <>
            <div style={{marginTop:"77px", }} >
                <div md={12} xs={12} className="backImg" style={{backgroundImage:"url('img/intro.jpg')",width: "100%", height:"150px",backgroundSize: "cover", backgroundRepeat: "no-repeat",    alignItems: "center"}}>
                    <div style={{backgroundColor: "#1414157d", height:"150px"}}>
                    <h2 style={{ padding:"69px", color:"#fff",fontWeight: "700"}}>INTRODUCTION</h2>
                    </div>
                </div>
                <Container>
                    
                            <div><h3 className="textAlignJustify aboutUs" ><strong>ABOUT US</strong>&nbsp;</h3></div>

                                
                                <Row>
                                    <Col>
                                        <div className=" mb-4 mt-3 text-center"><img src="img/chairman.jpg" className='chairmanImg'  /></div>
                                        <div className=" mb-4 mt-3 text-center">
                                           <text style={{fontSize: "19px",fontWeight: "700",letterSpacing: "3px", color: "#0b0b0bdb"}}> Sadikul Alam</text><br />
                                           <text style={{fontSize: "16px",fontWeight: "600",letterSpacing: "2px", color: "rgb(142 137 143)"}}>Chairman (DIRASA)</text><br />
                                           <text style={{fontSize: "13px",fontWeight: "600",letterSpacing: "0px", color: "rgb(138 132 138)"}}>B. Tech in Civil Engineer</text>
                                        </div>
                                        <div className=" mb-4 mt-3 ">
                                        Dear Students,<br />

                                        <text style={{marginLeft:"100px",color:"red"}}> Welcome to Dirasa</text>! 
I feel privileged to be able to connect to you all. Your faith in us has made DIRASA the fastest growing & best  institute for XI, XII with NEET preparation in Malda. To all those young minds who have a aim to succeed in toughest Entrance Examinations of the country and are ready to follow our guidance, i assure them to translate their dreams into reality. Our aim is to provide the best education to our students and will continue to be the hallmark.<br />
Wishing you all the best for your future endeavours.<br/>
Sincerely,<br/>
<text style={{fontSize: "16px",fontWeight: "600",letterSpacing: "1px", color: "#0b0b0bdb"}}> Sadikul Alam</text><br />
Chairman (DIRASA)<br />

                                        </div>

                                    </Col>
                                </Row>
                                <p className="textAlignJustify">&nbsp;</p>
                                <Row>
                                    <Col md={4}>
                                        <h4 className="textAlignJustify h4">WHO WE ARE</h4>
                                        <p className="textAlignJustify justify">DIRASA is one of the top Institutes in Malda for NEET (UG) and a well-known name in the education industry to provide valuable Edu-services to students.We have some great highly qualified teachers and trainers on our staff who have rich and professional teaching experience. Which gives you peace of mind.<br/>
                                         </p>
                                    </Col >
                                    <Col md={4} xs={10}>
                                        <h4 className="textAlignJustify h4">OUR VISION</h4>
                                        <p className=" justify">Malda is a populated city with emerging talents but unfortunately these emerging talents are not getting take off speed to fly due to lack of proper supportive coaching, now-a days. Where the other districts are getting numerous number of doctors but our district is left behind. So we are here to give them proper coaching and counselling so that they can live the life what the students and their parents dream off.
We can not only educate but also counsel a student for a better future roaming around the National Eligibility Cum Entrance Test  (NEET-UG).</p>
                                    </Col>
                                    <Col md={4} xs={10}>
                                    <h4 className="textAlignJustify h4">OUR FACULTIES</h4>
                                         <p className="textAlignJustify justify">Our Faculty members are specially trained to understand DIRASA academic system.We have some great highly qualified teachers and trainers on our staff who have rich and professional teaching experience .We provide our students with the facility to select their teachers so that the student – teacher bond becomes strong which further enhances the result of students.</p>
                                    </Col>
                                    
                                </Row>
                                

                                


                               

                                
                                
                       
                </Container>
            </div>
            </>
    )
}
export default AboutUs