import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from 'axios';
import PrintForm from "./PrintForm";
import { Redirect } from 'react-router-dom';



class RegistrationNew extends React.Component {
    state = {
        fname: '',
        lname: '',
        parentage: '',
        schoolname: '',
        class: '',
        email: '',
        contact: '',
        address: '',
        isDeclare: 0,
        dist: '',
        how: '',
        loadarr: 0,
        dob: '',
        sex: '',
        disableLoader: 0,
        hsroll:'',
        adhar:'',

    }
    componentDidMount() {
        localStorage.setItem('sID', null);
        // let currentDate = new Date();
        // let year = currentDate.getFullYear();
        // for (let i=year;i<year+5;i++) {
        //   this.state.sessionList.push('B');
        //   if(i<year+5) this.state.loadarr=1;
        // }
        // console.log(this.state.sessionList);
    }

    handleInputChange = event => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [event.target.name]: value
        }, function () {
            console.log(this.state.fname)
            console.log(this.state.lname)
            console.log(this.state.parentage)
            console.log(this.state.schoolName)
            console.log(this.state.class)
            console.log(this.state.isDeclare)

        });
    }
    handleSubmit = event => {
        event.preventDefault();

        const data = {
            fname: this.state.fname,
            lname: this.state.lname,
            parentage: this.state.parentage,
            schoolname: this.state.schoolname,
            class: this.state.class,
            email: this.state.email,
            contact: this.state.contact,
            address: this.state.address,
            isDeclare: this.state.isDeclare,
            dist: this.state.dist,
            how: this.state.how,
            dob: this.state.dob,
            sex: this.state.sex,
            hsroll: this.state.hsroll,
            adhar: this.state.adhar,

        }
        this.setState({
            disableLoader: 1
        });
        if (!this.state.fname) {
            alert("PLease Enter First Name");
            this.setState({
                disableLoader: 0
            });
        } if (!this.state.lname) {
            alert("PLease Enter Last Name");
            this.setState({
                disableLoader: 0
            });
        } else {
            this.setState({
                            disableLoader: 1
                        });
            axios
                .post('https://dirasa.in/dirasaapi/registrationNew.php',{data})
                //.post('http://localhost/dirasaapi/registrationNew.php', { data })
                .then(res => {
                    console.log('res' + res);
                    console.log(res.data);
                    if (res.data > 1) {
                        localStorage.setItem('name', this.state.fname + ' ' + this.state.lname);
                        localStorage.setItem('email', this.state.email);
                        localStorage.setItem('roll_no', res.data);
                        this.setState({
                            disableLoader: 0
                        });
                        window.location = '/RegistrationSuccess';
                    } else {
                        alert("Something goes wrong!" + res.data);
                        this.setState({
                            disableLoader: 0
                        });
                        window.location.reload();
                    }

                });
        }
    }

    render() {
        return (
            <div style={{ marginTop: "200px", marginBottom: "100px" }} >
                {/*Loader*/}
                {this.state.disableLoader ?
                    <div class="loader"></div>
                    : ''}
                {/*End Loader*/}
                <Container>
                    <Row>
                        <Col>
                            <div className="text-center">
                                <img src="img/header.jpeg" style={{ width: "320px" }}></img>
                            </div>
                            {/* <div className="text-center" style={{ color: "red", fontFamily: "'Open Sans'", fontSize: "54px",letterSpacing: "15px",    marginTop: "-27px"}}> <u>DIRASA</u></div>
                            <div className="text-center" style={{ color: "red", fontFamily: "'Open Sans'", fontSize: "14px",    marginTop: "-14px"}}> WE ARE PROVIDING YOU A BETTER FUTURE </div> */}
                            <div className="text-center" style={{ marginTop: "16px", marginBottom: "50px" }}><span style={{ backgroundColor: "red", padding: "7px", borderRadius: "10px", color: "#fff", fontWeight: "700" }}>REGISTRATION FORM</span></div>
                            {/* <div className="text-center" style={{marginTop: "10px",color: "red",fontWeight: "700"}}>FOCUS ON : NEET</div> */}
                            <Form onSubmit={this.handleSubmit}>

                                <Row>
                                    <Col md={6}>
                                        <Form.Label className="level" >First Name*</Form.Label>
                                        <Form.Control type="text" name="fname" id="fname" placeholder="Enter First Name" onChange={this.handleInputChange} required />
                                        <Form.Text className="text-muted">
                                            Enter your first name.
                                        </Form.Text>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label className="level" >Last Name*</Form.Label>
                                        <Form.Control type="text" name="lname" id="lname" placeholder="Enter Last Name" onChange={this.handleInputChange} required />
                                        <Form.Text className="text-muted">
                                            Enter your last name.
                                        </Form.Text>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Label className="level" >Gender*</Form.Label>
                                        <Form.Control
                                            as="select"
                                            className="my-1 mr-sm-2"
                                            id="sex"
                                            name="sex"
                                            required
                                            onChange={this.handleInputChange}
                                            custom
                                        >
                                            <option value="">Choose...</option>
                                            <option value="Male" >Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Other">Other</option>
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Choose gender.
                                        </Form.Text>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label className="level" >Date of birth*</Form.Label>
                                        <Form.Control type="date" name="dob" id="dob" placeholder="Enter Date of birth" onChange={this.handleInputChange} required />
                                        <Form.Text className="text-muted">
                                            Enter date of birth.
                                        </Form.Text>
                                    </Col>
                                </Row>

                                <Form.Group >
                                    <Form.Label className="level" >Guardian Name*</Form.Label>
                                    <Form.Control type="text" name="parentage" id="parentage" placeholder="Enter Guardian Name" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter your Guardian Name.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level" >School Name*</Form.Label>
                                    <Form.Control type="text" name="schoolname" id="schoolname" placeholder="Enter School Name" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter your school name.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level" >Roll No (Higher Secondary  xxxx-xxxx)*</Form.Label>
                                    <Form.Control type="text" name="hsroll" id="hsroll" placeholder="Enter HS Roll No" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter your HS Roll No.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level" >Aadhaar No*</Form.Label>
                                    <Form.Control type="text" name="adhar" id="adhar" placeholder="Enter Aadhaar No" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter your Aadhaar No.
                                    </Form.Text>
                                </Form.Group>
                                {/* <Form.Group >
                                    <Form.Label className="level">Class*</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="my-1 mr-sm-2"
                                        id=""
                                        name="class"
                                        required
                                        onChange={this.handleInputChange}
                                        custom
                                    >
                                        <option value="">Choose...</option>
                                        <option value="8th" >8th</option>
                                        <option value="9th">9th</option>
                                        <option value="10th">10th</option>
                                        <option value="11th">11th</option>
                                        <option value="12th">12th</option>
                                        <option value="NEET">NEET</option>
                                        <option value="JEE">JEE</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Choose class.
                                    </Form.Text>
                                </Form.Group> */}
                                <Form.Group >
                                    <Form.Label className="level">Email*</Form.Label>
                                    <Form.Control type="email" placeholder="Email" name="email" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter Email.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">Phone Number*</Form.Label>
                                    <Form.Control type="number" placeholder="Phone Number" name="contact" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter Phone Number.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">Full Address*</Form.Label>
                                    <Form.Control type="text" placeholder="Address" name="address" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter Present Address.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">District*</Form.Label>
                                    <Form.Control type="text" placeholder="City" name="dist" onChange={this.handleInputChange} required />
                                    <Form.Text className="text-muted">
                                        Enter City.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">How did you hear about us?</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="my-1 mr-sm-2"
                                        id=""
                                        name="how"
                                        required
                                        onChange={this.handleInputChange}
                                        custom
                                    >
                                        <option value="">Choose...</option>
                                        <option value="Facebook" >Facebook</option>
                                        <option value="Parents">Parents</option>
                                        <option value="Direct">Direct</option>
                                        <option value="Friend">Friend</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Google">Google</option>
                                        <option value="Other">Other</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                        Choose Courses.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group >
                                    <Form.Check type="checkbox" name="isDeclare" label="	
I have read and agree to the Terms and Conditions." onChange={this.handleInputChange} required />
                                </Form.Group>
                                {/* <Form.Group >
                                <Button variant="danger" className="resButton" disabled={this.state.disableLoader} type="submit" size="lg" style={{ width: "60%" }} >
                                    Submit Form
                                </Button>
                                </Form.Group> */}
                                <Row>
                                    <Col md={12} lg={4}>
                                    <Button variant="danger"  disabled={this.state.disableLoader} type="submit" size="lg"  style={{ width: "100%" }} >
                                        Submit Form
                                    </Button>
                                    </Col>
                                </Row>



                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default RegistrationNew;