import React, { Component } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import axios from 'axios';
import PrintForm from "./PrintForm";
import {Redirect} from 'react-router-dom';
class Registration extends React.Component {
    state = {
            name:'',
            dob : '',
            address: '',
            course : '',
            session : '',
            schoolName: '',
            contact: '',
            isDeclare: 0,
            gname: '',
            gcontact:'',
            sessionList:[{text:'Choose...',value:''},
                          {text:'2022',value:'2022'}, 
                          {text:'2023',value:'2023'}, 
                          {text:'2024',value:'2024'}],
            loadarr:0
    
        }
        componentDidMount() {
            localStorage.setItem('sID', null);
            let currentDate = new Date();
            let year = currentDate.getFullYear();
            for (let i=year;i<year+5;i++) {
              this.state.sessionList.push('B');
              if(i<year+5) this.state.loadarr=1;
            }
            console.log(this.state.sessionList);
        }
       
    handleInputChange = event=>{
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState({
            [event.target.name]: value
        }, function(){
            console.log(this.state.name)
            console.log(this.state.dob)
            console.log(this.state.course)
            console.log(this.state.schoolName)
            console.log(this.state.session)
            console.log(this.state.isDeclare)

        });
    }
    handleSubmit= event=>{
        event.preventDefault();
        
        const data = {
            name : this.state.name,
            dob : this.state.dob,
            address : this.state.address,
            course : this.state.course,
            session : this.state.session,
            schoolName : this.state.schoolName,
            contact : this.state.contact,
            isDeclare : this.state.isDeclare,
            gname : this.state.gname,
            gcontact : this.state.gcontact,

        }
        if(!this.state.name){
            alert("PLease Enter Name");
           
        }else{

            axios 
                .post('https://dirasa.in/api/registration.php',{data})
                .then(res =>{
                    console.log('res'+ res);
                    console.log(res.data);
                    if(res.data>1){
                        localStorage.setItem('sID', res.data);
                        window.location = '/PrintForm';
                    }else{
                        alert("Something goes wrong!"+ res.data);
                    }
    
                });
        }
    }
    
    render() {
        return (
            <div style={{marginTop:"200px",marginBottom:"100px"}} >
                <Container>
                    <Row>
                        <Col>
                           <div className="text-center">
                               <img src="img/header.jpeg" style={{    width:"320px"}}></img>
                            </div>
                            {/* <div className="text-center" style={{ color: "red", fontFamily: "'Open Sans'", fontSize: "54px",letterSpacing: "15px",    marginTop: "-27px"}}> <u>DIRASA</u></div>
                            <div className="text-center" style={{ color: "red", fontFamily: "'Open Sans'", fontSize: "14px",    marginTop: "-14px"}}> WE ARE PROVIDING YOU A BETTER FUTURE </div> */}
                            <div className="text-center" style={{    marginTop: "16px", marginBottom:"50px"}}><span style={{backgroundColor: "red",padding: "7px",borderRadius: "10px",color: "#fff",fontWeight: "700"}}>APPLICATION FORM</span></div>
                            {/* <div className="text-center" style={{marginTop: "10px",color: "red",fontWeight: "700"}}>FOCUS ON : NEET</div> */}
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Group >
                                    <Form.Label className="level" >NAME</Form.Label>
                                    <Form.Control type="text" name="name" id="name" className="input-text"  placeholder="Enter Full Name" onChange={this.handleInputChange} required/>
                                    <Form.Text className="text-muted">
                                    Enter your full name.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level" >DATE OF BIRTH</Form.Label>
                                    <Form.Control type="date" name="dob"   placeholder="" onChange={this.handleInputChange}  required/>
                                    <Form.Text className="text-muted">
                                    Select DOB.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level" >GUARDIAN'S NAME</Form.Label>
                                    <Form.Control type="text" name="gname" id="gname" className="input-text"  placeholder="Enter Guardian's Name" onChange={this.handleInputChange} required/>
                                    <Form.Text className="text-muted">
                                    Enter Guardian's Name.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">ADDRESS</Form.Label>
                                    <Form.Control type="text" placeholder="Address" className="input-text" name="address" onChange={this.handleInputChange}  required/>
                                    <Form.Text className="text-muted">
                                    Enter Present Address.
                                    </Form.Text>
                                </Form.Group>
                                {/* <Form.Group >
                                    <Form.Label className="level">COURSE</Form.Label>
                                    <Form.Control type="text" placeholder="NEET / Other" className="input-text" name="course" onChange={this.handleInputChange} required/>
                                    <Form.Text className="text-muted">
                                    Enter Course Name (NEET).
                                    </Form.Text>
                                </Form.Group> */}
                                <Form.Group >
                                    <Form.Label className="level">COURSE</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="my-1 mr-sm-2"
                                        id=""
                                        name="course"
                                        required
                                        onChange={this.handleInputChange}
                                        custom 
                                    >
                                        <option value="">Choose...</option>
                                        <option value="CRASH COURSE (NEET)" >CRASH COURSE (NEET)</option>
                                        <option value="TEST SERIES PROGRAM">TEST SERIES PROGRAM</option>
                                        <option value="1 YEAR PROGRAM">1 YEAR PROGRAM</option>
                                        <option value="2 YEARS PROGRAM">2 YEARS PROGRAM</option>
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                         Choose Courses.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">SESSION</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="my-1 mr-sm-2"
                                        id=""
                                        name="session"
                                        required
                                        onChange={this.handleInputChange}
                                        custom 
                                    >
                                        {this.state.sessionList.map(v => {
                                         return   (<option value={v.value}>{v.text}</option>);
                                         })}
                                        {/*  <option value="">Choose...</option>
                                         <option value="2021">2021</option>
                                         <option value="2022">2022</option>
                                         <option value="2023">2023</option> */}
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                         Choose Session.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">NAME OF SCHOOL</Form.Label>
                                    <Form.Control type="text" className="input-text" placeholder="School Name" name="schoolName" onChange={this.handleInputChange} required/>
                                    <Form.Text className="text-muted">
                                    Enter your School Name.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">MOBILE NO.</Form.Label>
                                    <Form.Control type="number"  placeholder="Mobile No." name="contact" onChange={this.handleInputChange} required/>
                                    <Form.Text className="text-muted">
                                    Enter Mobile No.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group >
                                    <Form.Label className="level">GUARDIAN MOBILE NO.</Form.Label>
                                    <Form.Control type="number"  placeholder="Guardian's Mobile No." name="gcontact" onChange={this.handleInputChange} required/>
                                    <Form.Text className="text-muted">
                                    Enter Guardian's Mobile No.
                                    </Form.Text>
                                </Form.Group>

                              
                                <Form.Group >
                                    <Form.Check type="checkbox" name="isDeclare" label="I here by declare that all the information provided is correct." onChange={this.handleInputChange} required />
                                </Form.Group>
                                    
                                            <Button variant="danger" type="submit" size="lg" style={{    width: "40%"}} >
                                                Submit
                                            </Button>
                                        
                                    
                                
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Registration;