import React from 'react'
import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faPhoneAlt} from '@fortawesome/free-solid-svg-icons'

const Header = () => {
    return (
      <>
      <div class="elfsight-app-b1eb5c65-4830-4cc1-9e77-e65e6cdaa192"></div>
        <div id="top" style={{color:"#fff", backgroundColor: "#fd1f1f", paddingRight: "10%",    textAlign: "right", fontSize:"18px",paddingBottom: "30px", }}> Have any Questions <FontAwesomeIcon icon={faPhoneAlt} /> 03512-295952 &nbsp;/&nbsp;8697131519</div>
        <Navbar fixed="top" className="navbarTop navTopMargin nopadding" collapseOnSelect expand="lg" style={{backgroundColor: "#f8f9fa08!important",marginTop:"29px" }} variant="light">
        <Navbar.Brand href="/">
            <img
            src="/img/logo1.png"
            width="55"
            height="55"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
          <Navbar.Text style={{paddingBottom: "0rem",marginTop: "-11px",fontSize: "2.8rem"}}>dirasa</Navbar.Text>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#features">Features</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="Courses">Courses</Nav.Link>
            <Nav.Link href="/img/DirasaProspectus.pdf">Prospectus</Nav.Link>
            <Nav.Link href="Registration">Admission</Nav.Link>
            <Nav.Link href="AboutUs">About Us</Nav.Link>
            <Nav.Link href="ContactUs">Contact Us</Nav.Link>
            {/* <Nav.Link eventKey={2} href="#memes">
              Dank memes
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
        
      </>
    )
}

export default Header
